
import { onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "CountriesSelect",
  props: {
    country_id: {
      type: Number,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:country_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const inputCountry = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isCountryRequired = (value) => {
      if (props.required && !value) {
        return t("rcountry");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "countries",
      isCountryRequired
    );

    const getCountry = () => {
      store.commit("setLoading", false);
      ApiService.get("/api/countries/lists").then(({ data }) => {
        inputCountry.value.list = data.countries;
        inputCountry.value.options = data.countries;
      });
    };
    const selectCountry = (query) => {
      if (query !== "") {
        store.commit("setLoading", false);
        ApiService.query(`/api/countries/lists`, {
          params: { per_page: 25, name: query },
        }).then(({ data }) => {
          inputCountry.value.list = data.countries;
          inputCountry.value.options = data.countries;
        });
      } else {
        inputCountry.value.options = [];
      }
    };

    watch(
      () => props.country_id,
      (first) => {
        element_id.value = first;
        if (
          first != null &&
          !inputCountry.value.options.map((x) => x.id).includes(first) &&
          inputCountry.value.options.length > 0
        ) {
          ApiService.get("/api/countries/" + first).then(({ data }) => {
            inputCountry.value.options.push(data);
          });
        }
      }
    );

    onMounted(() => getCountry());

    return {
      element_id,
      inputCountry,
      errorMessage,
      getCountry,
      selectCountry,
    };
  },
};
